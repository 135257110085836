import React from 'react';
import Button from './Button';
import NavBar from './NavBar';
import Quote from './Quote';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';


const Home = () => {
    const quote = '“The most important thing in communication is hearing what isn’t said.” – Peter Drucker'
    return (
        <div>
            <NavBar textColour={'white'} />
            <section className="relative flex items-center h-screen bg-pots bg-cover" style={{ backgroundPosition: 'center bottom 20%' }}>
                <div className="absolute inset-0 bg-primary opacity-50"></div>
                <div className="w-10/12 mx-auto">
                    <div className="w-full md:w-10/12 float-left text-left text-white mt-72">
                        {/* <h1 className="z-5 relative text-4xl md:text-6xl font-bold merriweather-regular mb-8 leading-normal md:leading-normal">
                            Change is always possible when relationships are enhanced.
                        </h1> */}
                        <NavLink to="/workshops">
                        <h1 className="z-5 relative text-4xl md:text-6xl font-bold merriweather-regular mb-8 leading-normal md:leading-normal">
                            Change is always possible when relationships are enhanced.
                        </h1>
                        </NavLink>
                        {/* <h3 className="relative text-2xl leading-normal md:leading-loose open-sans">
                            Attachment focused, trauma informed practice utilizing neuroscience and positive intelligence to help individuals, couples, and families, not just to survive, but to thrive in life and in love.
                        </h3> */}
                    </div>
                </div>
            </section>
            <section className="text-white bg-primary">
                <div className="container mx-auto w-5/6 lg:w-8/12 py-32">
                    <h2 className="title">Therapeutic Approach</h2>
                    <p className="paragraph-text mb-8">
                    Attachment focused, trauma informed practice utilizing neuroscience and positive intelligence to help individuals, couples, and families to thrive in life and in love.  I look forward to working with you to help you rebuild, repair, and reconnect with the Self and your relationships with others!
                    </p>                   
                </div>
            </section>
            <section className="container mx-auto py-32 w-full lg:w-8/12 text-primary ">
                <h2 className="title">About Me</h2>
                <div className="flex flex-col lg:flex-row items-center justify-center">
                    <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
                        <img src={process.env.PUBLIC_URL + '/images/profile.jpg'} className="w-2/3 mx-auto lg:mx-0" alt="A casual photo of Debbie Wang smiling." />
                    </div>
                    <div className="w-5/6 lg:w-1/2 flex flex-col justify-center items-center">
                        <p className="mb-10 lg:mb-6 text-left text-2xl leading-loose open-sans">Authenticity and reflexivity are an integral part of my practice.  I am an effective communicator who is skilled in relating to people. By using simple concepts and a straightforward approach, I help my clients and workshop participants to discover and rediscover the best in them.
                        </p>
                        <Button to="/about" children="Learn More" className="lg:mt-4"></Button>
                    </div>
                </div>
            </section>
            <section className="w-full pt-16 pb-8 lg:pb-24 mx-auto bg-tertiary text-primary">
                <h2 className="title">Relationship Works</h2>
                <div className="flex flex-col lg:flex-row justify-between items-center mx-auto w-4/6">
                    <NavLink to="/counselling" className="flex flex-col items-center justify-center mb-14 lg:mb-0">
                        <img src={process.env.PUBLIC_URL + '/images/rocks.jpeg'} className="services-image bg-right" alt="A colourful array of rocks and red leaves." />
                        <h3 className="merriweather-regular text-2xl">Counselling</h3>
                    </NavLink>
                    <NavLink to="/workshops" className="flex flex-col items-center justify-center  mb-14 lg:mb-0">
                        <img src={process.env.PUBLIC_URL + '/images/red_flowers.jpeg'} className="services-image" alt="Red and orange flowers and green stems." />
                        <h3 className="merriweather-regular text-2xl">Workshops</h3>
                    </NavLink>
                    <NavLink to="/supervision" className="flex flex-col items-center justify-center mb-14 lg:mb-0">
                        <img src={process.env.PUBLIC_URL + '/images/evergreen2.jpeg'} className="services-image" alt="Green pinelike leaves." />
                        <h3 className="merriweather-regular text-2xl">Supervision</h3>
                    </NavLink>
                </div>

            </section>
            {/* <Quote text={quote} /> */}
            {/* <Testimonials /> */}
            <Footer bg={'white'} />
        </div>
    );
};



export default Home;