import React from 'react';
import NavBar from './NavBar';
import Accordion from './Accordion';
import Testimonials from './Testimonials';
import Hero from './Hero'
import Footer from './Footer';

const Counselling = () => {
    const testimonials = [
        {   imageUrl: process.env.PUBLIC_URL + '/images/door.jpeg', 
            heading: 'Attunement',
            text: '“I appreciate your flexibility in the way you work, your generosity and your kindness. Unlike many therapists, I can feel that you bend the structure to serve my healing rather than try and fit me into the \'box\' of the modality. I am very thankful for your creativity and openness and improvisational style.”',
            alt: 'Purple wooden door framed by red painted walls',
            client: 'Female client, age 30s', 
            isImageLeft: true, 
        },
        {   imageUrl: process.env.PUBLIC_URL + '/images/loggirl.jpeg', 
            heading: 'Empowerment',
            text: '“I did it!!! Thanks Debbie. That means A LOT coming from you. I’ve worked incredibly hard at getting out of that terrible place I was and am absolutely thrilled to have brought myself back to ME!”',
            alt: 'Wooden log sculpture',
            client: 'Female client, age 40s', 
            isImageLeft: false, 
        },
        {   imageUrl: process.env.PUBLIC_URL + '/images/logboy.jpeg',
            heading: 'Instilling Hope', 
            text: '“...we reached out to you when our marriage was in a volatile state. We initially began our counselling back in March with the target of the end of June to evaluate our relationship and we\'re happy to say this is a marriage we want to continue working on and we cannot thank you enough.”',
            alt: 'A wooden log inukshuk sculpture',
            client: 'Male client, age 30s', 
            isImageLeft: true, 
        },
    ]
    
    const quote = '"The most beautiful people we have known are those who have known defeat, known suffering, known struggle, known loss, and have found their way out of those depths."'
    
    const quoteName = ' - Elisabeth Kubler-Ross'

    return (
        <div className="mx-auto text-primary bg-tertiary">
            <NavBar textColour={'white'}/>
            {/* <Hero bg={'bg-counselling'} bgPosition={'bg-center'} text={quote} quoteName={quoteName} height={'h-screen'} /> */}

            <section className="relative flex bg-cover bg-counselling bg-center h-screen">

                <div className="absolute inset-0 bg-primary opacity-60"></div>
                <div className="w-10/12 mx-auto flex items-center justify-center text-3xl lg:text-5xl leading-loose lg:leading-normal font-bold text-white">
                    <div>
                        <h1 className="z-5 relative font-bold merriweather-regular text-center">
                            {quote}
                        </h1>
                        {quoteName ? <h1 className="text-right open-sans z-5 relative text-2xl lg:text-4xl">{quoteName}</h1> : null}
                    </div>
                </div>
            </section>
            <section className="template-section">

                <div className="template-container">
                    <h1 className="title">Counselling</h1>

                    <p className="leading-normal mb-12 w-5/6 mx-auto">
                        A social worker by profession and trained in Emotionally Focused Therapy, my practice utilizes a humanistic perspective and lifespan developmental approach.  Through trauma-informed inquiry and the lens of attachment, I help individuals, couples, and families not just to survive, but to thrive in life and in love.
                    </p>
                    <p className="mb-12 w-5/6 mx-auto font-bold text-center">
                        “Attachment is the integral part of human nature from the cradle to the grave.” - Dr. John Bowlby
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                        I am especially effective in helping individuals and partners to understand their attachment styles (which affects our relationship with the Self and with significant others) and working towards embodying secure attachment, even if they had no models of this growing up. Through meaningful connections, each of us can forge new neural pathways towards secure attachment that leads to positive mental and relational health!
                    </p>
                </div>
            </section>
            <section className="w-full mx-auto">
                <div className="accordion-container">
                    <Accordion title="Individuals">
                        <p className="font-bold mb-6">“The poverty in the West is loneliness and emptiness.” - Mother Teresa</p>
                        <p className="mb-12">It is a common human need across time and cultures to feel a sense of belonging and acceptance; the need to love and be loved. Without good relationships or meaningful connections, life becomes lonely, difficult, and empty. As a result, many people become susceptible to unhealthy behaviours, social anxiety, and clinical depression.</p>
                        <p className="font-bold mb-6">"Self–Knowledge is the beginning of self–improvement." - Spanish Proverb</p>
                        <p className="mb-6">Through a therapeutic, reflective process, you will discover or rediscover who you are and make sense of the current situation at this stage of your life. The differences among counselling, therapy, and life coaching will be explained to help you find the best fit for your personal growth and developmental needs. Together, we will create a plan to help you make positive changes in life and reach your potential.
                        </p>
                        <p className="mb-6">
                        The determining factors for success are: your readiness for positive change, open to hope for your future, and my commitment to support and hold you accountable along the way.
                        </p>
                        {/* <p className="font-bold mb-6">Fee: $180.00 CAD/hr, $210.00 CAD/75 min</p> */}
                    </Accordion>
                    <Accordion title="Couples">
                        <p className="font-bold mb-6">“The greatest gift a parent has to give a child—and a lover has to give a lover—is emotionally attuned attention and timely responsiveness.” - Dr. Sue Johnson</p>
                        <p className="mb-6">
                            Each of us is hardwired for connection and closeness.  We all long for close, loving, long-term relationships.  Many feel isolated and alone when we are not able to attain or sustain such relationships. Emotionally Focused Therapy (EFT) is a highly researched, effective, and evidence-based treatment.  Based on the new science of love, it focuses on the emotional bond and security between partners.
                        </p>
                        <p className="mb-6">
                            Recognizing that most relational problems arise from a disruption of this bond, EFT helps couples to stop their isolation, break the negative cycles, and reach for one another more effectively.  I want to help couples resolve conflicts, repair hurts, deepen connections, and strengthen relationships. 
                        </p>
                        {/* <p className="font-bold mb-6">Fee: $180.00 CAD/hr, $210.00 CAD/75 min</p> */}
                    </Accordion>
                    <Accordion title="Families">
                        <p className="font-bold mb-6">“There are only two lasting bequests we can hope to give our children. One of these is roots, the other, wings.” - Johann Wolfgang von Goethe</p>
                        <p className="mb-6">
                            Families balance the need to belong with the need to become. I am most interested in helping families of parent(s) and adult children navigate their emotional dynamics and key attachment issues.
                        </p>
                        <p className="font-bold mb-6">
                            “In adult parent-child relationships, there are at least four people there. There's who you actually are. There's who the other actually is. Then there's who you believe the other is and who the other believes you are. Sometimes the real people are not even there. It's just the other two arguing with each other.” - Dr. Gabor Maté
                        </p>
                        <p className="mb-6">
                            I utilize Emotionally Focused Family Therapy (EFFT) to guide family members through past distress, disappointments, and injuries to create new relational patterns, stronger family bonds, and greater felt security. My goal is to create new patterns of emotional healing that engage a family’s natural ability to repair.
                        </p>
                        {/* <p className="font-bold mb-6">Fee: $180.00 CAD/hr, $210.00 CAD/75 min</p> */}
                    </Accordion>
                </div>
            </section>
            <section className='w-full mx-auto bg-primary text-white py-16'>
                {testimonials.map((testimonial, index) => (
                    <Testimonials key={index} {...testimonial} />      
                ))}
            </section>
            <Footer bg={'tertiary'} />
        </div>
    );
};

export default Counselling;